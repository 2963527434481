const accordion = {
  // Config
  cfg: {
    triggers: [...document.querySelectorAll('.accordion-trigger')],
    status: 'is-open',
  },

  // Toggle Content
  toggleContent(event) {
    event.preventDefault();
    const trigger = event.currentTarget;
    const accContent = trigger.nextElementSibling;
    const arrow = trigger.querySelector('.accordion-arrow');

    if (accContent.style.maxHeight) {
      accContent.style.maxHeight = null;
    } else {
      accContent.style.maxHeight = accContent.scrollHeight + "px";
    }

    arrow.classList.toggle('rotate-180');
  },


  // Init
  init() {
    this.cfg.triggers.forEach(trigger => {
      trigger.addEventListener('click', (event) => this.toggleContent(event));
    });
  },
};

// Export as default
export default accordion;
